<template>
  <div class="login">
    <div class="step1" v-if="stepIndex === 1">
      <div class="title">手机登录/注册</div>

      <div class="input-wrapper">
        <div class="text-wrapper"><span class="pre">+86</span><i class="iconfont">&#xe6e9;</i></div>
        <div class="phone-input"><input type="text" v-model="phone" /></div>
      </div>

      <!-- <div class="next-button" v-loading="true" @click="goSecondStep">下一步</div> -->
      <van-button :loading="loading" type="info" @click="goSecondStep" style="width: 90%; margin: 1rem 5% 0 5%">下一步</van-button>

      <div class="jigsaw-mask" v-show="showJigsaw">
        <div class="jigsaw-wrapper">
          <div class="tips">拖动下方滑块进行拼图验证</div>
          <div id="jigsaw-container" class="jigsaw-container"></div>
          <div class="success-mask" v-if="verifySuccessFlag">
            <div class="success-icon"><van-icon name="checked" color="#07c160" size="1.8rem" /></div>
            <div class="success-text"><span>操作成功</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="step2" v-if="stepIndex === 2">
      <div class="title">输入短信验证码:</div>
      <div class="tip">已向您的手机号{{ phone.substring(7) }}发送验证码</div>
      <div class="code-wrapper">
        <input ref="vcode" id="vcode" type="tel" maxlength="6" v-model="code" @focus="focused = true" @blur="focused = false" :disabled="telDisabled" />
        <label for="vcode" class="line" v-for="(item, index) in codeLength" :key="index" :class="{ animated: focused && cursorIndex === index }" v-text="codeArr[index]"> </label>
      </div>
      <div class="resend-tip" :style="{ color: seconds === 0 ? '#1890ff' : '#999999' }">
        <span @click="reSendVerifyCode">重新发送</span><span v-if="seconds !== 0">({{ seconds }}s)</span>
      </div>
      <div class="next-button" @click="submit">下一步</div>
    </div>
  </div>
</template>
<script>
// import Jigsaw from '@/utils/jigsaw'
import { auth, sendSms } from '@/api/open'
export default {
  data() {
    return {
      stepIndex: 1,
      showJigsaw: false,
      activeIndex: 1,
      value: '',
      verifySuccessFlag: false,
      jigsaw: null,
      phone: '',
      vcode: '',
      seconds: 0,
      timeout: 0,

      //
      code: '',
      codeLength: 6,
      telDisabled: false,
      focused: false,
      submitFlag: false,
      timer: null,
      loading: false,
    }
  },
  mounted() {
    // let clientWidth = document.body.clientWidth
    // this.jigsaw = new Jigsaw({
    //   el: document.getElementById('jigsaw-container'),
    //   width: clientWidth * 0.9 - 40, // 可选, 默认310
    //   height: ((clientWidth * 0.9 - 40) / 310) * 155, // 可选, 默认155
    //   onSuccess: () => {
    //     sendSms({
    //       prefixPhone: '86',
    //       phone: this.phone,
    //       smsType: 1,
    //     }).then(res => {
    //       if (res && res.code === 0) {
    //         this.verifySuccessFlag = true
    //         this.seconds = 120
    //         setTimeout(() => {
    //           this.stepIndex = 2
    //           this.startInterval()
    //         }, 1000)
    //       } else if (res.msg) {
    //         clearInterval(this.timeout)
    //         this.timeout = 0
    //         this.$notify({ type: 'danger', message: res.msg })
    //       }
    //     })
    //   },
    //   onFail: () => {
    //     console.log('onFail')
    //   },
    //   onRefresh: () => {
    //     console.log('onRefresh')
    //   },
    // })
    // this.jigsaw.init()
  },
  computed: {
    codeArr() {
      return this.code.split('')
    },
    cursorIndex() {
      return this.code.length
    },
  },
  watch: {
    code(newVal) {
      this.code = newVal.replace(/[^\d]/g, '')
      // if (newVal.length === 6) {
      //   // this.telDisabled = true
      //   this.$refs.vcode.blur()
      //   setTimeout(() => {
      //     this.submit()
      //     //alert(`vcode: ${this.code}`)
      //   }, 500)
      // }
    },
  },
  methods: {
    sendSms() {
      sendSms({
        prefixPhone: '86',
        phone: this.phone,
        smsType: 1,
      })
        .then(res => {
          if (res && res.code === 0) {
            this.verifySuccessFlag = true
            this.seconds = 120
            setTimeout(() => {
              this.stepIndex = 2
              this.startInterval()
            }, 1000)
          } else if (res.msg) {
            clearInterval(this.timeout)
            this.timeout = 0
            this.$notify({ type: 'danger', message: res.msg })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    reSendVerifyCode() {
      if (this.seconds !== 0) {
        return
      }
      this.seconds = 120
      sendSms({
        prefixPhone: '86',
        phone: this.phone,
        smsType: 1,
      }).then(res => {
        if (res && res.code === 0) {
          this.startInterval()
        } else if (res.msg) {
          this.seconds = 0
          this.$notify({ type: 'danger', message: res.msg })
        }
      })
    },
    goSecondStep() {
      if (/^1[3456789]\d{9}$/.test(this.phone)) {
        // this.showJigsaw = true
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.loading = true
          this.sendSms()
        }, 300)
      } else {
        this.$notify({ type: 'danger', message: '手机号码格式错误' })
      }
    },
    startInterval() {
      this.seconds = 120
      this.timeout = setInterval(() => {
        this.seconds--
        if (this.seconds <= 0) {
          this.seconds = 0
          clearInterval(this.timeout)
          this.timeout = 0
        }
      }, 1000)
    },
    submit() {
      if (this.submitFlag) {
        return
      }

      this.submitFlag = true
      const params = {
        openId: localStorage.openId,
        account: this.phone,
        password: '',
        vcode: this.code,
      }
      auth(params)
        .then(res => {
          if (res && res.code === 0) {
            const redirect = (this.$route.query || {}).redirect
            if (redirect === 'chatbot') {
              window.location.href = `${process.env.VUE_APP_MOLL_ORG}?token=${res.data.token}`
            } else {
              this.$store.commit('setUserInfo', res.data)
              this.$store.commit('setShowAiVideo', res.data.isShowAiVideo)
              this.$router.replace({
                path: '/authenticaResult',
                query: {
                  result: '1',
                },
              })
            }
          } else if (res.msg) {
            // this.$router.replace({
            //   path: '/authenticaResult',
            //   query: {
            //     result: '0',
            //   },
            // })
            this.$notify({ type: 'danger', message: res.msg })
          }
        })
        .finally(() => {
          this.submitFlag = false
        })
    },

    closeFlag() {
      this.verifySuccessFlag = false
      this.jigsaw.reset()
    },
  },
}
</script>
<style lang="scss">
@import '../utils/jigsaw.css';

@font-face {
  font-family: 'iconfont'; /* project id 2013799 */
  src: url('//at.alicdn.com/t/font_2013799_9csse8n0hmd.eot');
  src: url('//at.alicdn.com/t/font_2013799_9csse8n0hmd.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_2013799_9csse8n0hmd.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_2013799_9csse8n0hmd.woff') format('woff'), url('//at.alicdn.com/t/font_2013799_9csse8n0hmd.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_2013799_9csse8n0hmd.svg#iconfont') format('svg');
}
.login {
  .step1 {
    width: 100%;
    height: 100vh;
    padding-top: 5vh;
    box-sizing: border-box;
    .title {
      padding-left: 16px;
      font-size: 22px;
      font-weight: 600;
      text-align: left;
      color: #333333;
    }

    .input-wrapper {
      border-bottom: 1px solid #e3e7ed;
      width: 92%;
      margin: 1rem auto 0 auto;
      padding: 0.2rem 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-wrapper {
        padding-right: 10px;
        font-size: 20px;
        // .pre {
        // }
        i {
          margin-left: 10px;
          color: #1890ff;
        }
      }

      .phone-input {
        width: 100%;
        font-size: 20px;
        input {
          width: 100%;
          border: none;
        }
      }
    }

    .next-button {
      background-color: #1891ff;
      color: #ffffff;
      border-radius: 4px;
      width: 90%;
      padding: 18px 0;
      font-size: 0.4rem;
      margin: 1rem auto 0 auto;
      text-align: center;
    }

    .jigsaw-mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      .jigsaw-wrapper {
        width: 90%;
        margin: 15vh auto 0 auto;
        position: relative;
        background-color: #ffffff;
        padding: 20px 20px;
        border-radius: 6px;
        .tips {
          font-size: 20px;
          font-weight: 600;
          color: #333333;
        }

        .jigsaw-container {
          margin-top: 10px !important;
        }
        // width: 341px;
        // height: 170px;
        .success-mask {
          position: absolute;
          top: -0.01rem;
          left: 0;
          width: 100%;
          height: 101%;
          background-color: rgba(255, 255, 255, 0.9);
          padding-top: 1rem;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-around;
          .success-icon {
            text-align: center;
          }
          .success-text {
            font-size: 0.4rem;
            text-align: center;
            margin-top: 1rem;
          }
        }
      }
    }

    .sumbmit-btn {
      background-color: #1891ff;
      color: #ffffff;
      border-radius: 0.5rem;
      width: 80%;
      padding: 0.2rem 0;
      font-size: 0.4rem;
      margin: 1rem auto 0 auto;
      text-align: center;
    }
  }

  .step2 {
    width: 100%;
    height: 100vh;
    padding-top: 5vh;
    box-sizing: border-box;

    .title {
      padding-left: 16px;
      font-size: 24px;
      font-weight: 600;
      text-align: left;
      color: #333333;
    }

    .tip {
      margin-top: 6px;
      font-size: 16px;
      padding-left: 16px;
      color: #999999;
    }
    .code-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      input {
        position: absolute;
        top: 200%;
        opacity: 0;
      }

      .line {
        position: relative;
        width: 50px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 28px;
        .animated::before {
          display: block;
          position: absolute;
          left: 50%;
          top: 20%;
          width: 1px;
          height: 60%;
          content: '';
          background-color: #333333;
          animation-name: coruscate;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-fill-mode: both;
        }
      }

      .line::after {
        display: block;
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        bottom: 0;
        height: 1px;
        background-color: #aaaaaa;
        transform: scaleY(0.5);
        transform-origin: 0 100%;
      }
    }

    @keyframes coruscate {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    .resend-tip {
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
      color: #999999;
    }

    .next-button {
      background-color: #1891ff;
      color: #ffffff;
      border-radius: 4px;
      width: 90%;
      padding: 18px 0;
      font-size: 0.4rem;
      margin: 1rem auto 0 auto;
      text-align: center;
    }
  }
}
</style>
